/* Style for the table */
table {
  width: 100%;
  border-collapse: collapse;
}

table,
th,
td {
  border: 1px solid #ddd;
}

th,
td {
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

/* Style for the pagination */
.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.pagination-item {
  margin: 0 4px;
  cursor: pointer;
  font-weight: bold;
}

.pagination-item.active {
  color: #007bff;
}

.pagination-item.disabled {
  pointer-events: none;
  color: #ccc;
}

/* Custom styles for pagination */
.pagination-custom {
    display: flex;
    list-style: none;
    justify-content: center;
    margin: 0;
    padding: 0;
  }
  
  .page-item-custom {
    margin: 0 5px;
  }
  
  .page-link-custom {
    background-color: #007bff;
    color: #fff;
    border: 1px solid #007bff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .page-link-custom:hover {
    background-color: #0056b3;
    border: 1px solid #0056b3;
  }
  
 .editButton{
    background-color: #F1B452 !important;
  }

  .buttonRow {
    display: flex;
    gap: 8px; 
  }
  